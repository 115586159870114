import "./plugins/firebase";

import { createApp } from "vue";
import PrimeVue from "primevue/config";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Button from "primevue/button";
import Menubar from "primevue/menubar";
import Panel from "primevue/panel";
import InputText from "primevue/inputtext";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import PanelMenu from "primevue/panelmenu";
import ScrollPanel from "primevue/scrollpanel";
import Card from "primevue/card";
import App from "./App.vue";

const app = createApp(App)
app.use(ToastService);
app.use(PrimeVue);
app.component("Toast", Toast);
app.component("Button", Button);
app.component("Menubar", Menubar);
app.component("Panel", Panel);
app.component("InputText", InputText);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Splitter", Splitter);
app.component("SplitterPanel", SplitterPanel);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("PanelMenu", PanelMenu);
app.component("ScrollPanel", ScrollPanel);
app.component("Card", Card);

app.mount("#app");