<template>
  <section class="section">
    <Panel header="Shorter urls" class="">
      <LinkCreator />
      <!-- <ListItems v-show="isPathActive('/list')" :items="items" /> -->
    </Panel>
  </section>
</template>

<script>
require("primevue/resources/themes/saga-green/theme.css");
require("primevue/resources/primevue.css");
require("primeflex/primeflex.css");
require("primeicons/primeicons.css");
import LinkCreator from "./components/LinkCreator.vue";
// import ListItems from './ListItems/ListItems.vue'

export default {
  components: { LinkCreator },
  data() {
    return {
      // path: '',
      // items: [{ short: '', long: '' }],
    };
  },
  methods: {
    // async getItems() {
    //   const querySnapshot = await getDocs(collection(database, 'urls'))
    //   return querySnapshot.docs.map((doc) => {
    //     return {
    //       short: doc.get('short'),
    //       long: doc.get('long'),
    //     }
    //   })
    // }
  }

  // async created() {
  //  this.items = await this.getItems()
  // },
};
</script>

<style scoped></style>
