<template>
  <div>
    <h5>Shorten a URL</h5>
    <form
      @submit.prevent="generateURL(v$.fullURL.$invalid)"
      class="formgrid grid"
    >
      <div class="field p-field col-12 md:col-7 p-inputgroup">
        <InputText
          placeholder="Enter the URL to shorten as https://cesefor.com/page"
          v-model="v$.fullURL.$model"
          :class="{ 'p-invalid': v$.fullURL.$invalid && submitted }"
          @keyup="resetForm(v$.fullURL.$invalid)"
        />
        <Button label="Generate" type="submit" />
      </div>
      <div class="field col-12 md:col-12">
        <small
          id="fullURL-help"
          class="p-error"
          :class="{ hidden: !(v$.fullURL.$errors.length && submitted) }"
        >
          {{ v$.fullURL.url.$message }}
        </small>
      </div>
      <Card class="col-3" v-if="state.shortURL">
        <template #content>
          <a :href="BURL + state.shortURL" target="_blank">
            {{ BURL + state.shortURL }}
          </a>
        </template>
        <template #footer>
          <Button icon="pi pi-check" label="Copy" @click="copyText()" />
        </template>
      </Card>
    </form>
  </div>
  <Toast position="bottom-center" />
</template>

<script>
import { ref, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { url, required } from "@vuelidate/validators";
import {
  doc,
  setDoc,
  getDocs,
  query,
  collection,
  where,
  getFirestore
} from "firebase/firestore";

import { firebaseApp } from "../plugins/firebase";
import { customAlphabet } from "nanoid";

var alphabet = "123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 10);
const BURL = ref("https://forestURL.com/");
const db = getFirestore(firebaseApp);
const urlsCollection = collection(db, "urls");

export default {
  setup() {
    const state = reactive({
      fullURL: "",
      shortURL: ""
    });

    const rules = {
      fullURL: {
        url,
        required
      }
    };
    const submitted = ref(false);
    const v$ = useVuelidate(rules, state);
    // const toast = useToast();
    return { state, v$, submitted, BURL };
  },
  methods: {
    async generateURL(isInvalid) {
      this.submitted = true;
      if (isInvalid) {
        return;
      }

      try {
        const currentResult = await this.checkURL();
        if (currentResult) {
          this.state.shortURL = currentResult.data.short;
          return;
        }
        let newHash = nanoid();
        const data2Save = {
          short: newHash,
          long: this.state.fullURL
        };
        await setDoc(doc(urlsCollection), data2Save);
        this.state.shortURL = newHash;
        this.$toast.add({
          severity: "success",
          // summary: '',
          detail: "URL generada correctamente",
          life: 3000
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },
    toggleDialog() {
      if (!this.showMessage) {
        this.resetForm();
      }
    },
    async checkURL() {
      const q = query(urlsCollection, where("long", "==", this.state.fullURL));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        let content;
        querySnapshot.forEach((doc) => {
          content = { id: doc.id, data: doc.data() };
          console.log(doc.id, " => ", doc.data());
        });
        return content;
      }
      return false;
    },
    resetForm(invalid) {
      if (invalid) {
        this.fullURL = "";
        this.submitted = false;
      }
    },
    copyText() {
      navigator.clipboard.writeText(this.BURL + this.state.shortURL);
      this.$toast.add({
        severity: "success",
        // summary: '',
        detail: "URL copiada correctamente",
        life: 3000
      });
    }
  }
};
</script>
